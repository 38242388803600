@use '../setting/index' as *;

.l-footer {
  padding: 51px 0 90px;
  background-color: #433625;
  @include mq-min(md) {
    padding-top: min(120px, 9.524vw);
    padding-bottom: min(120px, 9.524vw);
  }
}

.l-footer__inner {
  @include inner-width;
}

.l-footer__logo {
  display: block;
  width: 208px;
  @include mq-min(md) {
    width: clamp(240px, 25.833vw, 280px);
  }
}

.l-footer__list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 1.3rem;
  color: #fff;
  margin-top: 12px;
  padding-top: 20px;
  border-top: 1px solid #818181;
  @include mq-min(md) {
    row-gap: 12px;
    font-size: 1.5rem;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    margin-left: min(30px, 2.5vw);
    padding-left: min(30px, 2.5vw);
    border-left: 1px solid #818181;
  }
}

.l-footer__list-item:last-of-type {
  position: relative;
  padding-left: 30px;
  background: url(/assets/images/icon/icon_mail.svg) no-repeat left top / 20px 15px;
  transition: color .3s, background-image .3s;
}
.l-footer__list-item:last-of-type:hover {
  @include mq-min(md) {
    color: #74B847;
    background-image: url(/assets/images/icon/icon_mail_green.svg);
  }
}


.l-footer__instagram {
  display: block;
  width: 30px;
  height: 30px;
  margin-top: 30px;
  @include mq-min(md) {
    margin-top: 0;
    transition: opacity .3s;
  }
}
.l-footer__instagram:hover {
  @include mq-min(md) {
    opacity: .5;
  }
}

.l-footer__copy {
  display: block;
  font-size: 1rem;
  color: #fff;
  margin-top: 10px;
  @include mq-min(lg) {
    margin: 21px 0 0 auto;
  }
  @include mq-min(md) {
    width: fit-content;
    font-size: 1.2rem;
    margin: 30px auto 0;
  }
}


// gridの設定 ↓↓
.l-footer__pc-grid {
  @include mq-min(lg) {
    grid-template-columns: fit-content(310px) repeat(2, 1fr) 30px;
    align-items: start;
  }
  @include mq-min(md) {
    display: grid;
    grid-template-columns: repeat(2, auto) 30px 30px;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
  }
}

.l-footer__logo {
  @include mq-min(lg) {
    grid-area: 1 / 1 / 4 / 2;
  }
  @include mq-min(md) {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.l-footer__list {
  @include mq-min(lg) {
    grid-area: 1 / 2 / 4 / 3;
  }
  @include mq-min(md) {
    grid-area: 1 / 2 / 3 / 3;
  }
}

.l-footer__instagram {
  @include mq-min(md) {
    grid-area: 2 / 4 / 3 / 5; 
  }
}

.l-footer__copy {
  @include mq-min(lg) {
    grid-area: 3 / 3 / 4 / 5;
  }
  @include mq-min(md) {
    grid-area: 3 / 1 / 4 / 5;
  }
}
// gridの設定 ↑↑