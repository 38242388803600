$base_font: 'Noto Sans JP', sans-serif;
$font_serif: 'Noto Serif JP', serif;

$font_color: #333;
$accent_color: #8F0B42;
$border_color: #707070;

$translateY_value: min(16px, 2.133vw);
$translateY_value_negative: max(-16px, -2.133vw);

$sp_header_menu_adjust_height: calc(((var(--vh, 1vh) * 100) - 667px) / 2);