@use '../setting/index' as *;

* {
  box-sizing: border-box;
  letter-spacing: 0.1em;
}

::before,
::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  line-height: 1.5;
  font-family: $base_font;
  color: $font_color;
  scroll-behavior: smooth;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}
main {
  overflow-x: hidden;
}
img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

a[href*="tel:"] {
  @include mq-min(md) {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}