@use '../setting/index' as *;

.p-news {
  padding: 30px 0 60px;
  background-color: #F1F1F1;
  @include mq-min(md) {
    padding: 117px 0 200px;
  }
}

.p-news__inner {
  @include inner-width-min;
}

.p-news__date {
  display: block;
  font-weight: bold;
  font-size: 1.4rem;
  color: #a5a5a5;
  @include mq-min(md) {
    font-weight: normal;
    font-size: 1.6rem;
  }
}

.p-news__heading {
  font-family: $font_serif;
  font-size: 2.2rem;
  margin-top: 8px;
  padding-bottom: 9px;
  border-bottom: 1px dotted #999;
  @include mq-min(md) {
    font-weight: 500;
    font-size: 3.4rem;
    margin-top: 16px;
    padding-bottom: 21px;
  }
}

.p-news__content {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-top: 20px;
  @include mq-min(md) {
    padding-top: 30px;
    row-gap: 20px;
  }
}

.p-news__image {
  @include mq-min(md) {
    max-width: 800px;
    width: max-content;
    margin: auto;
  }
}

.p-news__text {
  font-size: 1.6rem;
}

.p-news__button {
  display: block;
  width: max-content;
  font-family: $font_serif;
  font-weight: 500;
  font-size: 1.6rem;
  padding: 0 28px 8px 10px;
  margin: 60px auto 0;
  border-bottom: 1px solid #333;
  @include mq-min(md) {
    padding: 0 68px 20px 40px;
    margin-top: 70px;
    transition: color .3s, border-color .3s;
  }
}
.p-news__button:hover {
  @include mq-min(md) {
    color: #74B847;
    border-color: #74B847;
  }
}
.p-news__button span {
  position: relative;
}
.p-news__button span::before {
  content: '';
  position: absolute;
  top: 8px;
  right: -15px;
  width: 9px;
  height: 9px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(45deg);
  @include mq-min(md) {
    right: -27px;
    border-top-width: 2px;
    border-right-width: 2px;
    transition: border-color .3s;
  }
}
.p-news__button:hover span::before {
  @include mq-min(md) {
    border-color: #74B847;
  }
}