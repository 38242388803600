@use '../setting/index' as *;

.l-header {
  position: relative;
  @include mq-min(md) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    z-index: 50;
  }
}
.l-header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: min(120px, 16vw);
  background: linear-gradient(180deg, #000 0%, transparent 100%);
  opacity: 0.5;
  z-index: 2;
  @include mq-min(md) {
    content: none;
  }
}

.l-header__overlay {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000, $alpha: .4);
  z-index: -1;
  opacity: 0;
  @include mq-min(md) {
    display: none;
  }
}
.is-open .l-header__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 35;
  opacity: 1;
}

.l-header__inner {
  overflow: hidden;
}

.l-header__nav-wrap {
  position: fixed;
  top: 0;
  right: 0;
  width: min(580px, 77.333vw);
  height: 100%;
  transform: translateX(100%);
  transition: transform .5s;
  z-index: 35;
  overflow-y: scroll;
  @include mq-min(md) {
    display: contents;
  }
}


.is-open .l-header__nav-wrap {
  transform: translateX(0);
}

.l-header__logo {
  position: absolute;
  top: 15px;
  left: 15px;
  width: min(360px, 48vw);
  z-index: 30;
  @include mq-min(md) {
    position: static;
    display: block;
    width: clamp(160px, 20vw, 300px);
    padding-left: min(70px, 5.469vw);
  }
}

.l-header__text {
  display: none;
  @include mq-min(md) {
    display: block;
    font-size: min(1.6rem, 1.25vw);
    font-weight: bold;
    color: #797979;
    padding-left: min(60px, 4.688vw);
  }
}

.l-header__text span {
  @include mq-min(md) {
    background: linear-gradient(to right, #2c7000 0%, #74b847 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.l-header__nav {
  background-color: #F1EEE8;
  z-index: 5;
  @include mq-min(md) {
    background-color: #F3F3F3;
  }
}

.l-header__nav-list {
  padding-top: min(132px, 17.6vw);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: max(8px, $sp_header_menu_adjust_height);
  @include mq-min(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #D6D4CD;
    padding: 0;
  }
}

.l-header__nav-item {
  display: block;
  font-size: 1.6rem;
  font-family: $font_serif;
  padding: 19px 0;
  border-top: 1px solid #D6D4CD;
  @include mq-min(md) {
    position: relative;
    width: min(180px, 17.578vw);
    font-size: clamp(1.2rem, 1.367vw, 1.4rem);
    font-family: $base_font;
    text-align: center;
    border-top: none;
    border-right: 1px solid #fff;
    padding: 10px 0;
  }
}

.l-header__nav-item::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    border-top: 2px solid #A41919;
    transition: width .5s;
  }
}
.l-header__nav-item:hover::before {
  @include mq-min(md) {
    width: 100%;
  }
}

.l-header__nav-list li:first-child .l-header__nav-item {
  border-top: 1px solid #D6D4CD;
  @include mq-min(md) {
    border-top: none;
    border-left: 1px solid #fff;
  }
}

.l-header__nav-item span {
  display: none;
  @include mq-min(md) {
    display: block;
    font-size: 1.2rem;
    color: #ADADAD;
  }
}

.l-header__cta-list {
  padding: 30px 16px 26px 30px;
  padding-bottom: max(26px, $sp_header_menu_adjust_height);
  background-color: #E5DFD4;
  @include mq-min(md) {
    display: none;
  }
}

.l-header__cta-list li {
  font-size: 1.4rem;
  font-weight: bold;
}

.l-header__cta-item-image {
  width: 30px;
}

.l-header__cta-item-tel {
  margin-top: 10px;
}

.l-header__cta-item-text {
  margin-top: 18px;
}

.l-header__cta-item-mail {
  padding-left: 30px;
  margin-top: 3px;
  background: url(/assets/images/icon/icon_mail_black.svg) no-repeat left 3px / 20px 15px;
}

.l-header__cta-item-mail a {
  letter-spacing: 0;
}

.l-header__button {
  display: grid;
  align-content: center;
  justify-content: start;
  height: 76px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #A41919;
  padding-left: 30px;
  z-index: 20;
  @include mq-min(md) {
    place-content: center;
    width: min(324px, 30.313vw);
    height: min(90px, 7.031vw);
    font-size: clamp(1.2rem, 1.094vw, 1.4rem);
    text-align: center;
    letter-spacing: 0.03em;
    padding-left: 0;
  }
}

.l-header__button-num {
  display: block;
  width: max-content;
  font-size: 1.8rem;
  font-weight: bold;
  padding-left: 20px;
  background: url(/assets/images/icon/icon_tel.svg) no-repeat left 4px / 15px 20px;
  letter-spacing: 0.1em;
  margin-bottom: 5px;
  @include mq-min(md) {
    font-size: clamp(1.2rem, 1.875vw, 2.4rem);
    background-size: min(20px, 1.563vw) min(26px, 2.031vw);
    padding-left: min(32px, 2.5vw);
    margin: 0 auto 5px;
    letter-spacing: 0.05em;
    background-position-y: min(5px, 0.625vw);
  }
}

.l-header__sp-menu-button {
  position: absolute;
  top: min(30px, 4vw);
  right: min(24px, 3.2vw);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: min(72px, 9.6vw);
  height: min(76px, 10.133vw);
  font-size: min(2.4rem, 3.2vw);
  font-weight: bold;
  color: #fff;
  z-index: 40;
  @include mq-min(md) {
    display: none;
  }
}

.is-fixed .l-header__sp-menu-button {
  position: fixed;
  top: 0;
  right: 0;
  display: grid;
  width: min(120px, 16vw);
  height: min(120px, 16vw);
  color: #333;
  padding-bottom: 8px;
  background: #fff;
  z-index: 50;
  @include mq-min(md) {
    display: none;
  }
}

.is-open .l-header__sp-menu-button {
  background: transparent;
  @include mq-min(md) {
    display: none;
  }
}

.l-header__sp-menu-button-line {
  position: absolute;
  top: min(16px, 2.133vw);
  right: 0;
  display: inline-block;
  width: min(40px, 5.333vw);
  height: 2px;
  background-color: #fff;
}
.is-fixed .l-header__sp-menu-button-line {
  top: min(44px, 5.867vw);
  right: min(24px, 3.2vw);
  background-color: #333;
}

.l-header__sp-menu-button-line::before {
  content: '';
  position: absolute;
  top: max(-16px, -2.133vw);
  right: 0;
  width: min(68px, 9.067vw);
  height: 2px;
  background-color: #fff;
  animation: bottomline 1s ease forwards;
}

@keyframes bottomline {
  
  0% {
    transform: translateY($translateY_value) rotate(20deg);
    background-color: #333;
  }
  50% {
    transform: translateY($translateY_value);

  }
  100% {
    transform: translateY(0);
    background-color: inherit;
  }
}



.l-header__sp-menu-button-line::after {
  content: '';
  position: absolute;
  bottom: max(-16px, -2.133vw);
  right: 0;
  width: min(50px, 6.667vw);
  height: 2px;
  background-color: #fff;
  animation: upperline 1s ease forwards;
}
@keyframes upperline {
  0% {
    transform: translateY($translateY_value_negative) rotate(-20deg);
    background-color: #333;
  }
  50% {
    transform: translateY($translateY_value_negative);

  }
  100% {
    transform: translateY(0);
    background-color: inherit;
  }
}


.is-open .l-header__sp-menu-button {
  color: #333;
}

.is-open .l-header__sp-menu-button-line {
  background-color: transparent;
}

.is-open .l-header__sp-menu-button-line::before {
  width: min(80px, 10.667vw);
  animation: bottomline-active 1s ease forwards;
  background-color: #fff;
}
@keyframes bottomline-active {
  0% {
    transform: translateY(0);
    background-color: #fff;
  }
  50% {
    transform: translateY($translateY_value);

  }
  100% {
    transform: translateY($translateY_value) rotate(20deg);
    background-color: #333;
  }
}

.is-open .l-header__sp-menu-button-line::after {
  width: min(80px, 10.667vw);
  animation: upperline-active 1s ease forwards;
  background-color: #fff;
}

@keyframes upperline-active {
  0% {
    transform: translateY(0);
    background-color: #fff;
  }
  50% {
    transform: translateY($translateY_value_negative);

  }
  100% {
    transform: translateY($translateY_value_negative) rotate(-20deg);
    background-color: #333;
  }
}

.is-fixed .l-header__sp-menu-button {
  animation: sp-button-fix ease forwards;
}

.is-fixed .l-header__sp-menu-button-line::before {
  background-color: #333 !important;
}

.is-fixed .l-header__sp-menu-button-line::after {
  background-color: #333 !important;
}

@keyframes sp-button-fix {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// grid設定
.l-header__pc-grid {
  @include mq-min(md) {
    display: grid;
    grid-template-columns: fit-content(230px) 1fr fit-content(324px);
    grid-template-rows: auto;
    align-items: center;
  }
}

.l-header__logo {
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.l-header__text {
  @include mq-min(md) {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.l-header__button {
  @include mq-min(md) {
    grid-area: 1 / 3 / 2 / 4;
  }
}

.l-header__nav {
  @include mq-min(md) {
    grid-area: 2 / 1 / 3 / 4;
  }
}