@use '../setting/index' as *;

.p-loopslide-wrap {
  display: flex;
  width: max-content;
  margin-top: 30px;
  overflow: hidden;
  @include mq-min(md) {
    margin-top: min(70px, 5.556vw);
  }
}

.p-loopslide {
  display: flex;
  align-items: center;
  @include mq-min(md) {
    width: max-content;
  }
}

.p-loopslide:first-of-type {
  backface-visibility: hidden;
  will-change: transform, animation;
  animation: loopslide01 90s -45s linear infinite;
}

.p-loopslide:last-of-type {
  backface-visibility: hidden;
  will-change: transform, animation;
  animation: loopslide02 90s linear infinite;
}

.p-loopslide__item {
  width: 42.667vw;
  margin: 0 1px;
  @include mq-min(md) {
    width: min(320px, 16.667vw);
    margin: 0 2px;
  }
}

.p-loopslide__image {
  display: block;
}

.p-loopslide__image img {
  max-height: 220px;
  object-fit: cover;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
  .p-loopslide__image img {
    image-rendering: -webkit-optimize-contrast;
  }
}


@keyframes loopslide01 {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes loopslide02 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}