@use '../setting/index' as *;

.c-mv {
  position: relative;
}
.c-mv::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.52) 47.71%, rgba(0, 0, 0, 0.2) 100%);
  opacity: .8;

  @include mq-min(md) {
    background: linear-gradient(to right, #000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: .5;
  }
}
.c-mv--achievement {
  position: relative;
}
.c-mv--achievement::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.52) 47.71%, rgba(0, 0, 0, 0.2) 100%);
  opacity: .8;
  @include mq-min(md) {
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 47.71%, rgba(0, 0, 0, 0) 100%);
    opacity: .5;
  }
}

%c-mv__heading {
  position: absolute;
  top: min(261px, 34.8vw);
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  font-size: 1.6rem;
  color: #fff;
  font-family: $font_serif;
  text-align: center;
  @include mq-min(md) {
    top: 34%;
    font-size: clamp(1.2rem, 1.587vw, 2rem);
  }
}

%c-mv__heading::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  font-family: $base_font;
  font-weight: bold;
  font-size: 3rem;
  color: rgba(214, 212, 205, .4);
  line-height: 1;
  letter-spacing: 0.05em;
  @include mq-min(md) {
    font-size: clamp(4rem, 5.556vw, 7rem);
  }
}

%c-mv__heading span {
  display: block;
  font-size: 3rem;
  margin-top: 6px;
  @include mq-min(md) {
    font-size: clamp(2rem, 3.175vw, 4rem);
    margin-top: min(16px, 1.27vw);
  }
}

.c-mv__heading--achievement {
  @extend %c-mv__heading;
  &::before {
    content: 'Achievement';
    top: 51px;
  }
  @include mq-min(md) {
    &::before {
      top: min(54px, 4.286vw);
    }
  }
}
.c-mv__heading--company {
  @extend %c-mv__heading;
  &::before {
    content: 'About\ASankenzaimoku';
    white-space: pre;
    line-height: 1;
    top: 51px;
  }
  @include mq-min(md) {
    &::before {
      content: 'About Sankenzaimoku';
      white-space: initial;
      top: min(58px, 4.603vw);
    }
  }
}
.c-mv__heading--news {
  @extend %c-mv__heading;
  &::before {
    content: 'News Information';
    top: 27px;
  }
  @include mq-min(md) {
    &::before {
      top: min(24px, 1.905vw);
    }
  }
}
