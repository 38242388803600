@use '../setting/index' as *;

.p-achievement-categorys {
  padding: 20px 0;
  background-color: #F1F1F1;
  @include mq-min(md) {
    padding-top: min(50px, 3.968vw);
    padding-bottom: min(40px, 3.175vw);
  }
}

.p-achievement-categorys__inner {
  @include inner-width;
  @include mq-min(md) {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
}

.p-achievement-categorys__title {
  font-weight: bold;
  font-size: 1.4rem;
}

.p-achievement-categorys__list {
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-top: 10px;
  @include mq-min(md) {
    column-gap: 10px;
    margin-top: 0;
  }
}
.p-achievement-categorys__item a {
  display: grid;
  place-content: center;
  min-width: 100px;
  min-height: 28px;
  font-size: 1.4rem;
  text-align: center;
  padding: 0 5px;
  border: 1px solid #333;
  @include mq-min(md) {
    padding: 0 10px;
    transition: background-color .3s, color .3s;
  }
}
.p-achievement-categorys__item a:hover {
  @include mq-min(md) {
    color: #fff;
    background-color: #333;
    cursor: pointer;
  }
}
.p-achievement-categorys__item a.is-current {
  background-color: #333;
  color: #fff;
  @include mq-min(md) {
    cursor: auto;
  }
}

.p-achievement-contents {
  padding: 30px 0;
  background-color: #F1F1F1;
  @include mq-min(md) {
    padding-top: min(70px, 5.556vw);
    padding-bottom: min(70px, 5.556vw);
  }
}

.p-achievement-contents:nth-of-type(even) {
  background-color: #fff;
}

.p-achievement-contents__inner {
  @include inner-width;
  display: flex;
  flex-direction: column-reverse;
  @include mq-min(md) {
    flex-direction: row;
    align-items: flex-start;
    column-gap: min(60px, 4.6875vw);
  }
}
.p-achievement-contents__images {
  margin-top: 25px;
  @include mq-min(md) {
    width: 54.167%;
    margin-top: 0;
  }
}

.p-achievement-contents__main-image {
  transition: all .3s;
}

.p-achievement-contents__sub-images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  width: fit-content;
  max-width: calc( ((min(78px, 10.4vw)) * 7) + 30px );
  margin: 10px auto 0;
  @include mq-min(md) {
    gap: 10px;
    max-width: 100%;
    margin: 20px 0 0;
  }
}
.p-achievement-contents__thumbnail {
  width: min(78px, 10.4vw);
  height: min(54px, 7.6vw);
  @include mq-min(md) {
    width: calc((100% - 60px) / 7);
    height: auto;
  }
}
.p-achievement-contents__thumbnail img {
  opacity: .4;
  transition: opacity .3s;
  cursor: pointer;
}
.p-achievement-contents__thumbnail img:hover {
  @include mq-min(md) {
    opacity: 1;
  }
}
.p-achievement-contents__thumbnail img.is-current {
  opacity: 1;
  cursor: auto;
}

.p-achievement-contents__text-area {
  @include mq-min(md) {
    flex: 1;
    padding-top: 5%;
  }
}
.p-achievement-contents__heading {
  font-family: $font_serif;
  font-weight: 500;
  font-size: 2rem;
  margin-top: 16px;
  @include mq-min(md) {
    font-size: clamp(2rem, 2.063vw, 2.6rem);
    margin-top: min(20px, 1.587vw);
  }
}

.p-achievement-contents__heading span {
  display: block;
  font-family: $base_font;
  font-weight: bold;
  font-size: 1.2rem;
  color: #a41919;
  margin-bottom: 10px;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-bottom: min(10px, 0.794vw);
  }
}

.p-achievement-contents__detail {
  margin-top: 18px;
  @include mq-min(md) {
    margin-top: min(50px, 3.968vw);
  }
}

.p-achievement-contents__sub-heading {
  font-weight: bold;
  font-size: 1.6rem;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.p-achievement-contents__description {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  border-top: 1px solid #999;
}

.p-achievement-contents__description dt {
  width: 4em;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 20px 0;
  border-bottom: 1px solid #D6D4CD;
  @include mq-min(md) {
    width: 6em;
    font-size: 1.6rem;
  }
}

.p-achievement-contents__description dd {
  width: calc(100% - 4em);
  min-height: 62px;
  font-size: 1.4rem;
  padding: 20px 0;
  border-bottom: 1px solid #D6D4CD;
  @include mq-min(md) {
    width: calc(100% - 6em);
    min-height: 65px;
    font-size: 1.6rem;
  }
}

.p-achievement-pagination {
  padding: 60px 0 120px;
  background-color: #F1F1F1;
  @include mq-min(md) {
    padding-top: min(100px, 7.937vw);
    padding-bottom: min(150px, 11.905vw);
  }
}

.p-achievement-pagination__inner {
  position: relative;
  width: fit-content;
  margin: auto;
  @include mq-min(md) {
  }
}

.p-achievement-pagination__numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
  @include mq-min(md) {
    column-gap: 10px;
  }
}
.p-achievement-pagination__number {
  display: grid;
  place-content: center;
  width: 40px;
  height: 40px;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 2px;
  background-color: #fff;
  @include mq-min(md) {
    width: 52px;
    height: 52px;
    font-size: 2rem;
    transition: background-color .3s;
    cursor: pointer;
  }
}
.p-achievement-pagination__number:hover {
  @include mq-min(md) {
    background-color: #acdc8d;
  }
}
.p-achievement-pagination__number a {
  display: block;
}
.p-achievement-pagination__number.is-current {
  background: #d6d4cd;
  @include mq-min(md) {
    cursor: auto;
  }
}
.p-achievement-pagination__number.is-current:hover {
  @include mq-min(md) {
    background: #d6d4cd;
  }
}
.p-achievement-pagination__lists {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  margin-top: 20px;
  @include mq-min(md) {
    margin-top: 0;
  }
}
.p-achievement-pagination__list-item {
  min-width: 40px;
  min-height: 40px;
  font-size: 1.6rem;
  border-radius: 2px;
  background-color: #fff;
  @include mq-min(md) {
    min-width: 52px;
    min-height: 52px;
    transition: background-color .3s;
  }
}
.p-achievement-pagination__list-item:hover {
  @include mq-min(md) {
    background-color: #d6d4cd;
    cursor: pointer;
  }
}
.p-achievement-pagination__list-item a {
  display: block;
}
.p-achievement-pagination__list-item.prev {
  position: relative;
  @include mq-min(md) {
    position: absolute;
    top: 0;
    left: -90px;
  }
}
.p-achievement-pagination__list-item.prev a {
  position: relative;
  padding: 8px 10px 8px 19px;
  @include mq-min(md) {
    padding: 13px 13px 13px 33px;
  }
}
.p-achievement-pagination__list-item.prev a::before {
  content: '';
  position: absolute;
  top: 43%;
  left: 8px;
  width: 9px;
  height: 9px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(-135deg);
  @include mq-min(md) {
    top: 45%;
    left: 16px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
  }
}
.p-achievement-pagination__list-item.next {
  position: relative;
  @include mq-min(md) {
    position: absolute;
    top: 0;
    right: -90px;
  }
}
.p-achievement-pagination__list-item.next a {
  position: relative;
  padding: 8px 19px 8px 10px;
  @include mq-min(md) {
    padding: 13px 28px 13px 17px;
  }
}
.p-achievement-pagination__list-item.next a::before {
  content: '';
  position: absolute;
  top: 43%;
  right: 8px;
  width: 9px;
  height: 9px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(45deg);
  @include mq-min(md) {
    top: 45%;
    right: 15px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
  }
}
.p-achievement-pagination__list-item.first {
  position: relative;
  @include mq-min(md) {
    position: absolute;
    top: 0;
    left: -150px;
  }
}

.p-achievement-pagination__list-item.first::before {
  content: '';
  position: absolute;
  top: 43%;
  left: 14px;
  width: 9px;
  height: 9px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(-135deg);
  @include mq-min(md) {
    top: 41%;
    left: 19px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
  }
}
.p-achievement-pagination__list-item.first::after {
  content: '';
  position: absolute;
  top: 43%;
  right: 9px;
  width: 9px;
  height: 9px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(-135deg);
  @include mq-min(md) {
    top: 41%;
    right: 18px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
  }
}
.p-achievement-pagination__list-item.first a {
  display: block;
  min-height: 40px;
  @include mq-min(md) {
    min-height: 52px;
  }
}
.p-achievement-pagination__list-item.last {
  position: relative;
  @include mq-min(md) {
    position: absolute;
    top: 0;
    right: -150px;
  }
}

.p-achievement-pagination__list-item.last::before {
  content: '';
  position: absolute;
  top: 43%;
  left: 9px;
  width: 9px;
  height: 9px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(45deg);
  @include mq-min(md) {
    top: 41%;
    left: 19px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
  }
}
.p-achievement-pagination__list-item.last::after {
  content: '';
  position: absolute;
  top: 43%;
  right: 14px;
  width: 9px;
  height: 9px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(45deg);
  @include mq-min(md) {
    top: 41%;
    right: 18px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
  }
}

.p-achievement-pagination__list-item.last a {
  display: block;
  min-height: 40px;
  @include mq-min(md) {
    min-height: 52px;
  }
}

// Wordpress pagenation style
.p-achievement-pagination__inner .pagination ul.page-numbers {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  width: fit-content;
  margin: auto;
  @include mq-min(md) {
    column-gap: 10px;
  }
}

.p-achievement-pagination__inner .pagination li .page-numbers {
  display: grid;
  place-content: center;
  width: 40px;
  height: 40px;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 2px;
  background-color: #fff;
  @include mq-min(md) {
    width: 52px;
    height: 52px;
    font-size: 2rem;
    transition: background-color .3s, opacity .3s;
    cursor: pointer;
  }
}
.p-achievement-pagination__inner .pagination li .page-numbers:hover {
  @include mq-min(md) {
    background: #d6d4cd;
  }
}
.p-achievement-pagination__inner .pagination li .page-numbers a {
  display: block;
}
.p-achievement-pagination__inner .pagination li .page-numbers.current {
  background: #acdc8d !important;
  opacity: 1 !important;
  @include mq-min(md) {
    cursor: auto;
  }
}
.p-achievement-pagination__inner .pagination li .page-numbers.current:hover {
  opacity: 1 !important;
  @include mq-min(md) {
    background: #acdc8d !important;
  }
}
.p-achievement-pagination__inner .pagination li .page-numbers.dots {
  background: transparent !important;
  opacity: 1 !important;
  cursor: auto;
}
.p-achievement-pagination__inner .pagination li .page-numbers.dots:hover {
  opacity: 1 !important;
  @include mq-min(md) {
    background: transparent !important;
  }
}
.p-achievement-pagination__inner .pagination li .page-numbers.next {
  width: fit-content;
  position: absolute;
  bottom: -60px;
  left: 52%;
  font-size: 1.6rem;
  padding: 8px 19px 8px 10px;
  @include mq-min(md) {
    position: relative;
    bottom: initial;
    left: initial;
    padding: 13px 28px 13px 17px;
  }
}

.p-achievement-pagination__inner .pagination li .page-numbers.next.is-center {
  left: 50%;
  transform: translateX(-50%);
  @include mq-min(md) {
    left: initial;
    transform: none;
  }
}

.p-achievement-pagination__inner .pagination li .page-numbers.next::before {
  content: '';
  position: absolute;
  top: 43%;
  right: 8px;
  width: 9px;
  height: 9px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(45deg);
  @include mq-min(md) {
    top: 45%;
    right: 15px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
  }
}

.p-achievement-pagination__inner .pagination li .page-numbers.prev {
  width: fit-content;
  position: absolute;
  bottom: -60px;
  right: 52%;
  font-size: 1.6rem;
  padding: 8px 10px 8px 19px;
  @include mq-min(md) {
    position: relative;
    bottom: initial;
    right: initial;
    padding: 13px 13px 13px 33px;
  }
}
.p-achievement-pagination__inner .pagination li .page-numbers.prev.is-center {
  right: 50%;
  transform: translateX(50%);
  @include mq-min(md) {
    right: initial;
    transform: none;
  }
}

.p-achievement-pagination__inner .pagination li .page-numbers.prev::before {
  content: '';
  position: absolute;
  top: 43%;
  left: 8px;
  width: 9px;
  height: 9px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(-135deg);
  @include mq-min(md) {
    top: 45%;
    left: 16px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
  }
}
