@use '../setting/index' as *;
@use '../../../node_modules/swiper/swiper-bundle.min.css';
@use '../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';

.p-top-button {
  position: relative;
  display: grid;
  place-content: center;
  min-height: 42px;
  font-size: 1.6rem;
  font-family: $font_serif;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 2px;

  @include mq-min(md) {
    min-height: 62px;
    border-radius: 4px;
    transition: color 0.3s ease-in-out;
    overflow: hidden;
  }
}
.p-top-button::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    background-color: #fff;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
}
.p-top-button:hover {
  @include mq-min(md) {
    color: #333;
  }
}
.p-top-button:hover::before {
  @include mq-min(md) {
    transform: translateX(0);
  }
}

.p-top-button span {
  position: relative;
  z-index: 3;
  padding-right: 25px;
}

.p-top-button span::before {
  content: '';
  position: absolute;
  top: 40%;
  right: 4px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg) translateY(-50%);
  transition: border-color 0.3s ease-in-out;
}

.p-top-button:hover span::before {
  border-top: 1px solid #333;
  border-right: 1px solid #333;
}

// TOPページ MV ↓↓
.p-top-mv {
  @include mq-min(md) {
    background: url(/assets/images/top/bg_mv.jpg) no-repeat center/cover;
  }
}
.p-top-mv__inner {
  position: relative;
  @include inner-width;
}

.p-top-mv__heading {
  position: absolute;
  top: min(180px, 24vw);
  right: min(60px, 8vw);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 3px;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  line-height: 1;
  z-index: 30;
  @include mq-min(md) {
    top: min(67px, 3.49vw);
    right: min(65px, 3.384vw);
    row-gap: min(6px, 0.3125vw);
  }
}

.p-top-mv__heading-jp {
  display: inline-block;
  font-size: min(6.4rem, 8.533vw);
  background-color: rgba(255, 255, 255, .7);
  padding: 12px 5px 8px;
  font-family: $font_serif;
  font-weight: 400;
  letter-spacing: 0.13em;
  @include mq-min(md) {
    font-size: clamp(2rem, 2.604vw, 5rem);
    padding: 12px 8px 8px;
    padding-top: clamp(6px, 0.625vw, 12px);
    padding-bottom: clamp(4px, 0.417vw, 8px);
    padding-right: clamp(4px, 0.417vw, 8px);
    padding-left: clamp(4px, 0.417vw, 8px);
    letter-spacing: 0.11em;
  }
}
.p-top-mv__heading-jp--small {
  position: relative;
  top: -2px;
  font-size: min(5.2rem, 6.933vw);
  @include mq-min(md) {
    top: -2px;
    font-size: clamp(1.4rem, 2.083vw, 4rem);
  }
}
.p-top-mv__heading-en {
  display: inline-block;
  font-size: min(2.4rem, 3.2vw);
  font-weight: 400;
  background-color: rgba(255, 255, 255, .7);
  padding: 12px 4px;
  @include mq-min(md) {
    font-size: clamp(1.2rem, 0.9375vw, 1.8rem);
    padding-top: clamp(6px, 0.625vw, 12px);
    padding-bottom: clamp(6px, 0.625vw, 12px);
    padding-left: clamp(2px, 0.417vw, 8px);
    padding-right: clamp(2px, 0.417vw, 8px);
  }
}

.p-top-mv__slide-wrap {
  display: block;
  width: calc(100% + 60px);
  margin: 0 -30px;
  @include mq-min(md) {
    display: none;
    width: 100vw;
    margin: 0 calc(50% - 50vw);
  }
}

.p-top-mv__slide-wrap .p-top-mv__swiper-pagination {
  position: absolute;
  bottom: 25px;
  left: 30px;
  z-index: 10;
  @include mq-min(md) {
    display: none;
    max-width: 1260px;
    padding: 0 30px;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.p-top-mv__slide-wrap .swiper-pagination-bullet {
  position: relative;
  margin: 0 8px !important;
}

.swiper-pagination-bullet .center-circle {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: transparent;
  opacity: 1;
}

.swiper-pagination-bullet .outer-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 0;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active .center-circle {
  background-color: #fff;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active .outer-circle {
  opacity: 1;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active .animation-circle {
  position: absolute;
  top: -1px;
  left: -2px;
  transform: rotate(-90deg);
}

.swiper-pagination-bullet.swiper-pagination-bullet-active .animation-circle circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 1.5;
  animation: circle 28s ease none;
}

@keyframes circle {
  0% { stroke-dasharray: 0 366; }
  100% { stroke-dasharray: 366 366; }
}

.p-top-mv__movie {
  display: none;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  cursor: default;
  pointer-events: none;
  overflow: hidden;
  @include mq-min(md) {
    display: block;
  }
}
.p-top-mv__movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// TOPページ MV ↑↑

// TOPページ news ↓↓
.p-top-news {
  padding: 50px 0 30px;
  background-color: #F1F1F1;
  @include mq-min(md) {
    padding-top: min(70px, 6.836vw);
    padding-bottom: min(37px, 3.613vw);
    padding-left: 0;
    padding-right: 0;
  }
}
.p-top-news.is-little {
  @include mq-min(md) {
    padding-bottom: min(70px, 6.836vw);
  }
}

.p-top-news__inner {
  @include inner-width;
}

.p-top-news__pc-flex {
  @include mq-min(md) {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
    max-width: 1008px;
    margin: auto;
  }
}
.p-top-news.is-little .p-top-news__pc-flex {
  @include mq-min(md) {
    align-items: center;
  }
}

.p-top-news__heading {
  font-size: 3rem;
  font-family: $font_serif;
  font-weight: 500;
  text-align: center;
  @include mq-min(md) {
    font-size: min(4rem, 3.906vw);
    white-space: nowrap;
  }
}

.p-top-news__heading span {
  display: block;
  width: fit-content;
  font-size: 1.2rem;
  font-family: $base_font;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 2px 5px;
  background-color: #333;
  margin: 0 auto 9px;

  @include mq-min(md) {
    font-size: 1.4rem;
    margin-bottom: 22px;
    letter-spacing: 0.05em;
    padding: 3px 10px;
  }
}

.p-top-news__list {
  position: relative;
  width: calc(100% + 15px);
  max-height: 213px;
  margin-top: 18px;
  margin-right: -15px;
  padding-right: 10px;
  overflow-y: scroll;
  @include mq-min(md) {
    width: min(716px, 100%);
    margin: 0;
  }
}

.p-top-news__list::-webkit-scrollbar {
  width: 3px;
  @include mq-min(md) {
    width: 6px;
  }
}
.p-top-news__list::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: transparent;
}
.p-top-news__list::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #5DA92B;
}
// スクロールバー スマホ用の設定
.p-top-news__list .ps__rail-y {
  width: 3px;
  opacity: 1 !important;
}
.p-top-news__list .ps__rail-y:hover {
  opacity: 1 !important;
}
.p-top-news__list .ps__thumb-y {
  width: 3px;
  border-radius: 3px;
  background-color: #5DA92B;
  opacity: 1;
}
// スクロールバー スマホ用の設定ここまで

.p-top-news__item {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  padding: 10px 26px 10px 15px;
  border-top: 1px solid #BEBEBE;
  @include mq-min(md) {
    flex-direction: row;
    column-gap: 50px;
    padding: 20px 48px 20px 28px;
    transition: background-color .3s;
  }
}
.p-top-news__item[href]:hover {
  @include mq-min(md) {
    background-color: #E9E9E9;
  }
}
.p-top-news__list li:last-of-type .p-top-news__item {
  border-bottom: 1px solid #BEBEBE;
}

.p-top-news__item::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 13px;
  width: 9px;
  height: 9px;
  border-top: 2px solid #74B847 !important;
  border-right: 2px solid #74B847 !important;
  border-color: #74B847 !important;
  transform: translateY(-50%) rotate(45deg);
  @include mq-min(md) {
    right: 32px;
  }
}
.p-top-news__item[href=""] {
  pointer-events: none;
}
.p-top-news__item[href=""]::before {
  content: none;
}
.p-top-news__date {
  font-size: 1.4rem;
  @include mq-min(md) {
    letter-spacing: 0.05em;
  }
}

.p-top-nwes__text {
  font-size: 1.4rem;
  font-weight: bold;
  @include mq-min(md) {
    letter-spacing: 0.05em;
  }
}
// TOPページ news ↑↑

// TOPページ about ↓↓
.p-top-about {
  position: relative;
  padding: 60px 0;
  background: url(/assets/images/top/about_bg_sp.jpg) no-repeat center / cover;
  @include mq-min(md) {
    padding-top: min(100px, 7.937vw);
    padding-bottom: min(100px, 7.937vw);
    background: url(/assets/images/top/about_bg_pc.jpg) no-repeat center / cover;
  }
}

.p-top-about::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #291500;
  opacity: 0.4;
}

.p-top-about__inner {
  position: relative;
  @include inner-width;
}

.p-top-about__heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: rgba($color: #fff, $alpha: .5);
  letter-spacing: 0.05em;
  @include mq-min(md) {
    font-size: clamp(2rem, 3.968vw, 5rem);
    padding-top: min(24px, 1.905vw);
  }
}

.p-top-about__heading span {
  display: block;
  font-size: 3rem;
  color: #fff;
  font-family: $font_serif;
  font-weight: 500;
  margin-top: 20px;
  @include mq-min(md) {
    font-size: clamp(2rem, 3.492vw, 4.4rem);
    margin-top: min(14px, 1.111vw);
  }
}

.p-top-about_text {
  font-size: 1.6rem;
  font-family: $font_serif;
  color: #fff;
  margin-top: 19px;
  @include mq-min(md) {
    font-size: (1.2rem, 1.27vw, 1.6rem);
    margin-top: min(20px, 1.587vw);
  }
}

.p-top-about__images {
  margin-top: 30px;
  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-top-about__image {
  display: block;
  width: min(480px, 64vw);
  margin-left: auto;
  @include mq-min(md) {
    width: min(450px, 35.714vw);
  }
}

.p-top-about__image--small {
  display: block;
  width: min(300px, 40vw);
  margin-top: max(-196px, -26.133vw);
  @include mq-min(md) {
    width: min(280px, 22.222vw);
    margin-top: max(-196px, -15.556vw);
  }
}

.p-top-about__button-wrap {
  width: min(630px, 84vw);
  margin: 28px auto 0;
  @include mq-min(md) {
    width: min(300px, 23.809vw);
    margin-top: min(28px, 2.222vw);
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.p-top-about__pc-grid {
  @include mq-min(md) {
    display: grid;
    grid-template-columns: 0.9fr 1.1fr;
    grid-template-rows: auto;
    grid-column-gap: min(100px, 7.937vw);
    align-items: center
  }
}

.p-top-about__heading {grid-area: 1 / 1 / 2 / 2;}
.p-top-about_text {grid-area: 2 / 1 / 3 / 2;}
.p-top-about .p-top-button {grid-area: 3 / 1 / 4 / 2;}
.p-top-about__images {grid-area: 1 / 2 / 4 / 3;}
// TOPページ about ↑↑

// TOPページ achievement ↓↓
.p-top-achievement {
  position: relative;
  padding: 58px 0 60px;
  @include mq-min(md) {
    padding-top: min(100px, 7.937vw);
    padding-bottom: min(70px, 5.556vw);
  }
}
.p-top-achievement::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: min(1100px, 220vw);
  background: url(/assets/images/top/achievement_bg_sp.png) no-repeat center bottom / 100%;
  background-size: 100% min(1100px, 220vw);
  z-index: -1;
  @include mq-min(md) {
    height: min(500px, 39.682vw);
    background: url(/assets/images/top/achievement_bg_pc.png) no-repeat center bottom / cover;
  }
}

.p-top-achievement__inner {
  @include inner-width;
}

.p-top-achievement__heading {
  font-size: 1.6rem;
  font-family: $font_serif;
  text-align: center;
  font-weight: 500;
  @include mq-min(md) {
    font-size: clamp(1.4rem, 1.587vw, 2rem);
  }
}

.p-top-achievement__heading span {
  position: relative;
  display: block;
  font-size: 2.5rem;
  margin-top: 9px;
  @include mq-min(md) {
    font-size: clamp(2rem, 3.175vw, 4rem);
    margin-top: min(15px, 1.19vw);
  }
}

.p-top-achievement__heading span::before {
  content: 'Achievement';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 4rem;
  font-family: $base_font;
  font-weight: bold;
  color: #D6D4CD;
  letter-spacing: 0.05em;
  z-index: -1;
  opacity: .36;

  @include mq-min(md) {
    bottom: -33px;
    font-size: clamp(3rem, 5.556vw, 7rem);
  }
}

.p-top-achievement__figure-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 36px;
  @include mq-min(md) {
    flex-direction: row;
    column-gap: 2.5%;
    margin-top: min(85px, 6.746vw);
  }
}

.p-top-achievement__figure {
  width: min(630px, 84vw);
  margin: auto;
  @include mq-min(md) {
    width: calc((100% - 5%) / 3);
    margin: 0;
  }
}

.p-top-achievement__figcaption {
  display: grid;
  place-content: center;
  min-height: 40px;
  font-size: 1.6rem;
  font-family: $font_serif;
  font-weight: 500;
  text-align: center;
  background-color: rgba($color: #fff, $alpha: .9);
  
  @include mq-min(md) {
    font-size: clamp(1.2rem, 1.563vw, 1.6rem);
    min-height: 52px;
  }
}

.p-top-achievement__button-wrap {
  width: min(630px, 84vw);
  margin: 28px auto 0;
  @include mq-min(md) {
    width: 300px;
    margin-top: min(50px, 3.968vw);
  }
}
// TOPページ achievement ↑↑

// TOPページ company ↓↓
.p-top-company {
  padding: 212px 0 60px;
  background: url(/assets/images/top/company_bg_sp.jpg) no-repeat center / cover;
  @include mq-min(md) {
    padding-top: min(100px, 7.937vw);
    padding-bottom: min(90px, 7.143vw);
    background: url(/assets/images/top/company_bg_pc.jpg) no-repeat center / cover;
  }
}

.p-top-company__inner {
  position: relative;
  @include inner-width;
  @include mq-min(md) {
    display: flex;
    align-items: flex-start;
  }
}

.p-top-company__heading {
  width: fit-content;
  position: absolute;
  top: -222px;
  left: 52%;
  transform: translateX(-50%);
  font-size: 2.5rem;
  font-family: $font_serif;
  font-weight: 500;
  letter-spacing: 0.2em;
  writing-mode: vertical-rl;
  margin: 0 auto;
  z-index: 5;
  @include mq-min(md) {
    position: static;
    font-size: min(4rem, 3.175vw);
    margin-top: min(14px, 1.11vw);
    margin-left: min(87px, 6.905vw);
    margin-bottom: 0;
    margin-right: 0;
  }
}

.p-top-company__heading span {
  position: relative;
  display: block;
  font-size: 1.6rem;
  margin-top: 9px;
  margin-left: 4px;
  letter-spacing: 0.25em;

  @include mq-min(md) {
    font-size: min(2rem, 1.587vw);
    margin-top: min(12px, 0.982vw);
    margin-left: min(10px, 0.794vw);
  }
}

.p-top-company__heading span::before {
  content: '\201C';
  position: absolute;
  top: -14px;
  left: 0;
  font-size: 1.6rem;
  writing-mode: horizontal-tb;
  @include mq-min(md) {
    font-size: min(2rem, 1.587vw);
    top: max(-22px, -1.746vw);
  }
}

.p-top-company__heading span::after {
  content: '\201D';
  position: absolute;
  bottom: -23px;
  right: -3px;
  font-size: 1.6rem;
  writing-mode: horizontal-tb;
  @include mq-min(md) {
    font-size: min(2rem, 1.587vw);
    bottom: max(-28px, -2.22vw);
    right: max(-5px, -0.397vw);
  }
}

.p-top-company__heading::before {
  content: 'Company';
  position: absolute;
  top: -7px;
  left: -23px;
  font-size: 4rem;
  font-family: $base_font;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.05em;
  z-index: -1;
  @include mq-min(md) {
    font-size: min(7rem, 5.556vw);
    top: max(-13px, -1.032vw);
    left: max(-40px, -3.175vw);
  }
}

.p-top-company__figure {
  position: relative;
  width: min(630px, 84vw);
  margin: 77px auto 0;
  @include mq-min(md) {
    width: min(598px, 100%);
    margin: 0 0 0 3px;
    transition: opacity .5s ease-in-out;
  }
}
[class^="p-top-company__figure"] a {
  position: relative;
  display: block;
}
[class^="p-top-company__figure"] a::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    opacity: 0;
    transition: opacity .5s ease-in-out;
    z-index: 1;
  }
}
[class^="p-top-company__figure"] a:hover::before {
  @include mq-min(md) {
    opacity: .3;
  }
}

.p-top-company__image {
  position: relative;
  display: block;
  border-radius: 3px;
  border: 3px solid #fff;
  @include mq-min(md) {
    border-radius: 6px;
    border: 4px solid #fff;
    
  }
}
.p-top-company__image::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: min(360px, 48vw);
  height: min(140px, 18.667vw);
  background-color: rgba(75, 49, 16, .8);
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  @include mq-min(md) {
    width: min(220px, 17.46vw);
    height: min(120px, 9.524vw);
  }
}

.p-top--company__figcaption {
  position: absolute;
  left: 22px;
  bottom: 11px;
  font-size: 1.2rem;
  font-family: $font_serif;
  line-height: 1.7;
  color: #fff;
  @include mq-min(md) {
    bottom: 20px;
    font-size: 1.4rem;
  }
}

.p-top--company__figcaption span {
  display: block;
  font-size: 1.6rem;
  @include mq-min(md) {
    font-size: 2rem;
  }
}

.p-top-company__figure--pc-small {
  position: relative;
  width: min(630px, 84vw);
  margin: 20px auto 0;
  @include mq-min(md) {
    width: min(408px, 100%);
    margin: 0 0 0 18px;
  }
}

// TOPページ company ↑↑

// TOPページ access ↓↓
.p-top-access {
  padding: 30px 0 60px;
  background-color: #F1F1F1;
  scroll-margin-top: 0;
  @include mq-min(md) {
    padding-top: min(72px, 5.714vw);
    scroll-margin-top: 150px;
  }
}

.p-top-access__inner {
  @include inner-width;
}

.p-top-access__pc-flex {
  @include mq-min(md) {
    display: flex;
    align-items: center;
    column-gap: min(30px, 2.381vw);
  }
}

.p-top-access__heading {
  position: relative;
  font-size: 4rem;
  font-weight: bold;
  color: rgba($color: #D6D4CD, $alpha: .36);
  letter-spacing: 0.05em;

  @include mq-min(md) {
    font-size: min(7rem, 5.556vw);
  }
}

.p-top-access__heading span {
  position: absolute;
  top: 5px;
  left: 0;
  font-size: 2.5rem;
  font-family: $font_serif;
  font-weight: 500;
  color: #333;
  @include mq-min(md) {
    font-size: min(4rem, 3.175vw);
  }
}

.p-top-access__text {
  font-size: 1.6rem;
  margin-top: -2px;
}

.p-top-access__map {
  position: relative;
  width: 100%;
  padding-top: 76.19%;
  margin-top: 30px;
  @include mq-min(md) {
    padding-top: 40%;
    margin-top: 24px;
  }
}

.p-top-access__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-top-access__banner {
  position: relative;
  display: block;
  margin-top: 55px;
  border-radius: 5px;
  box-shadow: 0px 3px 0px #7a4400;

  @include mq-min(md) {
    width: min(710px, 56.349vw);
    margin-top: min(48px, 3.81vw);
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    transition: transform .3s, box-shadow .3s;
  }
}
.p-top-access__banner:hover {
  @include mq-min(md) {
    transform: translateY(3px);
    box-shadow: 0px 0px 0px #7a4400;
  }
}

.p-top-access__image {
  @include mq-min(md) {
    transition: opacity .3s;
  }
}
.p-top-access__banner:hover .p-top-access__image {
  @include mq-min(md) {
    opacity: .5;
  }
}

.p-top-access__banner img {
  border-radius: 5px;
  @include mq-min(md) {
    border-radius: 10px;
  }
}

.p-top-access__ballon {
  position: absolute;
  top: -26px;
  right: -15px;
  width: min(158px, 21.067vw);
  @include mq-min(md) {
    width: min(86px, 6.825vw);
    top: max(-29px, -2.302vw);
    right: max(-41px, -3.25vw);
  }
}
// TOPページ access ↑↑