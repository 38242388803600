@use '../setting/index' as *;

.p-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-content: center;
  transition: opacity .5s ease, transform .5s ease, visibility .5s ease;
  background-color: #F1F1F1;
  z-index: 99999;
}

.p-loading.is-loaded {
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100vw);
}

.p-loading__container {
  will-change: transform, opacity;
  animation: loading-fade-in 1s forwards;
  transition-timing-function: cubic-bezier(0.27, 0, 0.02, 1);
}

@keyframes loading-fade-in {
  0% {
    opacity: 0;
    transform: translateY(90px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-loading__logo {
  display: block;
  width: 200px;
  margin: auto;
  @include mq-min(md) {
    width: 400px;
  }
}

.p-loading__text {
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
  letter-spacing: 0.2em;
  margin-top: 20px;
  @include mq-min(md) {
    margin-top: 40px;
    padding-left: 23px;
  }
}

.p-loading__text span {
  position: relative;
  display: block;
  width: max-content;
  font-size: 1.6rem;
  margin: 16px auto 0;
  background: linear-gradient(to right, #2c7000 0%, #74b847 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include mq-min(md) {
    margin: 16px 0 0 67px;
  }
}

.p-loading__text span::before {
  content: '\201C';
  position: absolute;
  top: 0;
  left: -13px;
  font-weight: bold;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0.1em;
  -webkit-text-fill-color: initial;
}

.p-loading__text span::after {
  content: '\201D';
  position: absolute;
  top: 0;
  right: -13px;
  font-weight: bold;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0.1em;
  -webkit-text-fill-color: initial;
}

.p-loading__dot-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 30px;
  margin: 20px auto 0;
  @include mq-min(md) {
    margin: 50px auto 0;
  }
}

.p-loading__dot,
.p-loading__dot::before,
.p-loading__dot::after {
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  will-change: box-shadow;
  animation-fill-mode: both;
  animation: dot-loading-sp 1.8s infinite ease-in-out;

  @include mq-min(md) {
    width: 1.8em;
    height: 1.8em;
    animation: dot-loading 1.8s infinite ease-in-out;
  }
}
.p-loading__dot {
  position: relative;
  color: #5DA92B;
  margin-top: -1em;
  transform: translateZ(0);
  animation-delay: .68s;
  @include mq-min(md) {
    margin-top: -1.8em;
    animation-delay: .32s;
  }

}
.p-loading__dot::before,
.p-loading__dot::after {
  content: '';
  position: absolute;
  top: 0;

}
.p-loading__dot::before {
  left: -3.2em;
  animation-delay: .52s;
  @include mq-min(md) {
    animation-delay: .16s;
  }
}
.p-loading__dot::after {
  left: 3.2em;
  animation-delay: .84s;
  @include mq-min(md) {
    animation-delay: .54s;
  }
}

@keyframes dot-loading {
  0%, 80%, 100% {
    box-shadow: 0 1.8em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1.8em 0 0;
  }
}
@keyframes dot-loading-sp {
  0%, 80%, 100% {
    box-shadow: 0 1.2em 0 -1em;
  }
  40% {
    box-shadow: 0 1.2em 0 0;
  }
}
