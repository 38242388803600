@use '../setting/index' as *;

// 会社概要ページで使いまわすcomponent ↓↓
.p-company-heading {
  font-weight: bold;
  font-size: 1.4rem;
  overflow: hidden;
  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-company-heading span {
  position: relative;
  display: block;
  width: fit-content;
  font-family: $font_serif;
  font-weight: 500;
  font-size: 2.7rem;
  margin-top: 10px;
  @include mq-min(md) {
    font-size: clamp(2rem, 2.698vw, 3.4rem);
    margin-top: 20px;
  }
}

.p-company-heading span::before {
  content: '';
  position: absolute;
  bottom: 22px;
  left: calc(100% + 8px);
  width: 100vw;
  border-top: 1px solid #707070;
  @include mq-min(md) {
    left: calc(100% + 35px);
    bottom: min(22px, 1.846vw);
  }
}

.p-company-sub-heading {
  font-family: $font_serif;
  font-weight: 500;
  font-size: 2.2rem;
  margin-top: 16px;
  @include mq-min(md) {
    font-size: clamp(1.8rem, 2.381vw, 3rem);
    margin-top: min(50px, 3.968vw);
  }
}
.p-company-sub-heading ruby {
  display: inline-flex;
  flex-direction: column-reverse;
  align-items: center;

}
.p-company-sub-heading rt {
  font-size: 1rem;
  margin-bottom: -4px;
  @include mq-min(md) {
    font-size: min(1.2rem, 0.952vw);
  }
}

.p-company-sub-heading span {
  display: block;
  font-family: $base_font;
  font-weight: bold;
  font-size: 1.2rem;
  color: #d6d4cd;
  margin-bottom: 10px;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-bottom: 22px;
  }
}
// 会社概要ページで使いまわすcomponent ↑↑


.p-company-vison {
  padding: 22px 0 60px;
  background-color: #F1F1F1;
  @include mq-min(md) {
    padding-top: min(70px, 5.556vw);
    padding-bottom: min(100px, 7.937vw);
  }
}

.p-company-vison__inner {
  @include inner-width;
}

.p-company-vison__pc-grid {
  @include mq-min(lg) {
    display: flex;
    align-items: center;
    column-gap: min(110px, 9.1667%);
  }
  @include mq-min(md) {
    display: grid;
    grid-template-columns: 1fr 5vw 1fr;
    grid-template-rows: auto;
  }
}


.p-company-vison__text-area {
  @include mq-min(lg) {
    display: block;
    width: 41.667%;
  }
  @include mq-min(md) {
    display: contents;
  }
}

.p-company-vison__text-area .p-company-heading {
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 4;
  }
}

.p-company-vison__text-area .p-company-sub-heading {
  @include mq-min(md) {
    grid-area: 2 / 1 / 3 / 2;
  }
}

.p-company-vison__text {
  font-size: 1.6rem;
  margin-top: 20px;
  @include mq-min(md) {
    margin-top: 30px;
    grid-area: 3 / 1 / 4 / 2;
  }
}

.p-company-vison__image {
  display: block;
  margin-top: 30px;
  @include mq-min(lg) {
    width: 49.1667%;
    margin-top: 0;
  }
  @include mq-min(md) {
    width: 100%;
    margin-top: 50px;
    grid-area: 2 / 3 / 4 / 4;
  }
}

.p-company-full-image {
  display: block;
}

.p-company-message {
  padding: 30px 0 60px;
  background-color: #fff;
  scroll-margin-top: 0;
  @include mq-min(md) {
    padding-top: min(100px, 7.937vw);
    padding-bottom: min(70px, 5.556vw);
    scroll-margin-top: 130px;
  }
}

.p-company-message__inner {
  @include inner-width;
}

.p-company-message__pc-2col {
  display: flex;
  flex-direction: column-reverse;
  @include mq-min(md) {
    display: block;
  }
}

.p-company-message__text-area {
  @include mq-min(md) {
    padding-left: min(50px, 3.968vw);
  }
}

.p-company-message__text {
  font-size: 1.6rem;
  margin-top: 20px;
  @include mq-min(md) {
    margin-top: 30px;
  }
}

.p-company-message__text + .p-company-message__text {
  margin-top: 1.5em;
}

.p-company-message__ceo-name {
  width: 210px;
  margin: 20px 0 0 auto;
  @include mq-min(md) {
    width: 100%;
    text-align: right;
    margin-top: min(50px, 3.968vw);
    margin-left: 0;
  }
}

.p-company-message__ceo-name img {
  @include mq-min(md) {
    width: 220px;
  }
}

.p-company-message__image {
  position: relative;
  display: block;
  margin-top: 40px;
  padding-right: 10px;
  @include mq-min(md) {
    width: 41.667%;
    margin-top: 20px;
    margin-right: min(50px, 3.968vw);
    margin-bottom: 20px;
    margin-left: min(50px, 3.968vw);
    padding-right: 0;
    float: right;
  }
}

.p-company-message__image::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 0;
  border-right-width: min(300px, 40vw);
  border-bottom-width: min(300px, 40vw);
  border-left-width: 0;
  border-color: transparent #A41919 transparent transparent;
  z-index: 1;
  @include mq-min(md) {
    top: -20px;
    right: -20px;
    border-width: 0 300px 300px 0;
  }
}

.p-company-message__image img {
  position: relative;
  z-index: 2;
}

.p-company-outline {
  padding: 34px 0 60px;
  background-color: #F1F1F1;
  scroll-margin-top: 0;
  @include mq-min(md) {
    padding-top: min(70px, 5.556vw);
    padding-bottom: min(70px, 5.556vw);
    scroll-margin-top: 130px;
  }
}

.p-company-outline__inner {
  @include inner-width;
}

.p-company-outline__description {
  font-size: 1.6rem;
  margin-top: 6px;
  @include mq-min(md) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 28px;
    padding-left: min(50px, 3.968vw);
    padding-right: min(50px, 3.968vw);
  }
}

.p-company-outline__description dt {
  font-weight: bold;
  padding-top: 10px;
  @include mq-min(md) {
    width: 222px;
    padding-top: min(22px, 1.746vw);
    padding-bottom: min(22px, 1.746vw);
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-bottom: 1px solid #D9D9D9;
  }
}

.p-company-outline__description dd {
  padding: 10px 0;
  border-bottom: 1px solid #D9D9D9;
  @include mq-min(md) {
    width: calc(100% - 222px);
    padding-top: min(22px, 1.746vw);
    padding-bottom: min(22px, 1.746vw);
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.p-company-history {
  padding: 33px 0 60px;
  background-color: #fff;
  @include mq-min(md) {
    padding-top: min(70px, 5.556vw);
    padding-bottom: min(200px, 15.873vw);
  }
}

.p-company-history__inner {
  @include inner-width;
}

.p-company-hidtory__description {
  font-size: 1.6rem;
  margin-top: 5px;
  @include mq-min(md) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 26px;
    padding-left: min(50px, 3.968vw);
    padding-right: min(50px, 3.968vw);
  }
}

.p-company-hidtory__description dt {
  font-weight: bold;
  padding-top: 12px;
  @include mq-min(md) {
    width: 145px;
    padding-top: min(24px, 1.905vw);
    padding-bottom: min(24px, 1.905vw);
    padding-left: min(15px, 1.19vw);
    padding-right: min(15px, 1.19vw);
    border-bottom: 1px solid #D9D9D9;
  }
}

.p-company-hidtory__description dd {
  padding: 10px 0 12px;
  border-bottom: 1px solid #D9D9D9;
  @include mq-min(md) {
    width: calc(100% - 145px);
    padding-top: min(24px, 1.905vw);
    padding-bottom: min(24px, 1.905vw);
    padding-right: min(15px, 1.19vw);
    padding-left: min(74px, 5.873vw);
  }
}