@use '../setting/index' as *;

.l-sp-float-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-content: center;
  height: 60px;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  background-color: #A41919;
  z-index: 20;
  @include mq-min(md) {
    display: none;
  }
}


.l-sp-float-button__num {
  display: block;
  width: max-content;
  font-size: 1.8rem;
  font-weight: bold;
  padding-left: 21px;
  background: url(/assets/images/icon/icon_tel.svg) no-repeat left 4px / 15px 20px;
  margin: auto;
}